@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: bold;
  src: url('../assets/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
  font-family: inter;
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Inter-Regular.ttf') format('truetype');
}
